import React, { useEffect, useRef, useState } from 'react'

const Icon = ({ name, ...rest }) => {
  const ImportedIconRef = useRef(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!name) return null

    let mounted = true

    setLoading(true)
    const importIcon = async () => {
      try {
        const { default: namedImport } = await import(
          `!!@svgr/webpack?-svgo,+titleProp,+ref!assets/icons/${name}.svg`
        )
        ImportedIconRef.current = namedImport
      } catch (err) {
        throw err
      } finally {
        if (mounted) {
          setLoading(false)
        }
      }
    }
    importIcon()
    return () => {
      mounted = false
    }
  }, [name])

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef
    return <ImportedIcon {...rest} />
  }

  return null
}

export default Icon
