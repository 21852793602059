import { Box, Progress } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import ProgressiveImage from 'react-progressive-image'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import Container from 'components/Container'
import FlexGrid from 'components/FlexGrid'
import Icon from 'components/IconLoader'
import ImagePlaceholder from 'components/ImagePlaceholder'
import InfoSection from 'components/InfoSection'
import ScrollWrapper from 'components/ScrollWrapper'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react'

const API_URL = process.env.REACT_APP_API_URL

const StyledWrapper = styled.div`
  ${tw`flex flex-col h-full`}

  .chakra-collapse {
    overflow: visible !important;
  }
`

const Topic = styled.div`
  ${tw`w-full overflow-hidden relative rounded`};
`

const TopicImageWrapper = styled.div`
  ${tw`w-full h-screen-1/2 relative`};
`

const TopicOverlay = styled.div`
  ${tw`absolute inset-0 top-1/2 bg-gradient-to-b from-transparent to-black`};
  ${tw`flex items-end`};
`

const GridItem = styled.div`
  ${tw`w-full p-4 md:w-1/2`}
`

const Category = styled(Link)`
  ${tw`block w-full h-full overflow-hidden no-underline bg-white border rounded-lg`}
  ${tw`transition-shadow duration-200 ease-out shadow-md hover:shadow-lg`}
  ${tw`border-gray-200`}

  ${props => {
    if (props['data-hidden']) return tw`border-gray-500`
  }}
`

const CategoryInner = styled.div`
  ${tw`flex flex-col h-full`}
`

const CategoryTextBox = styled.div`
  ${tw`flex flex-col justify-between p-4 lg:p-8 h-full`}
`

const CategoryTitle = styled.span`
  ${tw`block text-2xl font-normal truncate`}
`

const StyledImageWrapper = styled.div`
  ${tw``}

  & img {
    height: calc(8rem + 12vw);
    max-height: 18rem;

    ${tw`object-cover object-center w-full`}
  }
`

const CategoryGrid = ({ categories }) => {
  // divide into topics with categories and categories without topic
  let topics = []
  const orphanedCategories = []

  categories.forEach(category => {
    const topic = topics.find(t => t.id === category.topic?.id)
    if (topic) topic.categories.push(category)
    else if (category.topic) {
      const topic = category.topic
      topic.categories = [category]
      topics.push(topic)
    } else {
      orphanedCategories.push(category)
    }
  })

  topics = topics.sort((a, b) => a.sort - b.sort)

  if (orphanedCategories.length > 0) {
    topics.unshift({
      sort: -1,
      name: 'Unzugeordnet',
      description: 'Kategorien ohne Thema',
      categories: orphanedCategories,
    })
  }

  const [isLoneTopic, setIsLoneTopic] = useState(topics.length === 1)

  const storedIndexes = sessionStorage.getItem('accordionIndex')
  const initialIndexes = storedIndexes ? JSON.parse(storedIndexes) : []

  const handleChange = (indexes, isLoneTopic) => {
    if (isLoneTopic) return

    const newIndexes = Array.isArray(indexes) ? indexes : [indexes]
    sessionStorage.setItem('accordionIndex', JSON.stringify(newIndexes))
  }

  useEffect(() => {
    // Accordion does not rerender correctly when the index changes so to make it work we reload the window
    setIsLoneTopic(currentIsLoneTopic => {
      if (currentIsLoneTopic !== (topics.length === 1)) window.location.reload()

      return currentIsLoneTopic
    })
  }, [topics.length])

  return (
    <StyledWrapper>
      <InfoSection />
      <ScrollWrapper className="mt-14">
        <Container>
          <Accordion
            display="flex"
            flexDirection="column"
            gridRowGap={8}
            w="100%"
            allowMultiple={false}
            allowToggle={true}
            defaultIndex={initialIndexes}
            onChange={indexes => handleChange(indexes, isLoneTopic)}
            index={isLoneTopic ? 0 : undefined}
          >
            <Topics topics={topics} isLoneTopic={isLoneTopic} />
          </Accordion>
        </Container>
      </ScrollWrapper>
    </StyledWrapper>
  )
}

const Topics = ({ topics, isLoneTopic }) => {
  return (
    topics &&
    topics.map(topic => {
      const isTopicHidden = topic.hidden === 1

      return (
        <Topic key={`topic-${topic.id}`}>
          <AccordionItem
            className="w-full"
            key={`topic-accordion-${topic.id}`}
            border={0}
          >
            <TopicImageWrapper>
              {renderImage(
                topic.media,
                topic.name,
                'h-full object-cover object-center'
              )}
              <TopicOverlay>
                <AccordionButton
                  className={`max-w-6xl ${isLoneTopic ? 'cursor-default' : ''}`}
                  px={12}
                  pb={20}
                  mx="auto"
                  color="white"
                  _hover={{ bg: 'none' }}
                  _focus={{ outline: 'none' }}
                >
                  <Box flex="1" textAlign="start" lineHeight="normal">
                    <h2 className="mb-2">{topic.name}</h2>
                    {topic.description && (
                      <p className="mb-2">{topic.description}</p>
                    )}
                    <strong>{topic.categories.length} Kategorien</strong>
                  </Box>
                  {!isLoneTopic && (
                    <AccordionIcon
                      className="bg-opacity-50 bg-black rounded-full"
                      p={1}
                      w={10}
                      h={10}
                      color="white"
                    />
                  )}
                </AccordionButton>
              </TopicOverlay>
            </TopicImageWrapper>

            <AccordionPanel pb={2} pt={4}>
              <CategoryItems categories={topic.categories} />
            </AccordionPanel>
          </AccordionItem>
          {isTopicHidden && (
            <div className="flex items-center absolute top-4 right-4 bg-opacity-75 bg-white px-4 py-2 rounded-full">
              <span className="mr-2">Ausgeblendetes Thema</span>
              <Icon
                className="w-6 h-6 text-gray-500 flex-shrink-0"
                name="alert-circle"
              />
            </div>
          )}
        </Topic>
      )
    })
  )
}

const CategoryItems = ({ categories }) => {
  return (
    <FlexGrid className="-mt-24">
      {categories &&
        categories.map(category => {
          const questionsCount = category.questions_count
          const answeredQuestionsCount = category.answered_questions_count
          const answeredQuestionsCorrectCount =
            category.answered_questions_correct_count

          const hasQuestions = questionsCount > 0
          const allQuestionsRight =
            answeredQuestionsCount === questionsCount &&
            answeredQuestionsCount === answeredQuestionsCorrectCount
          const questionProgress =
            (answeredQuestionsCount / questionsCount) * 100

          const isHidden = category.hidden === 1

          return (
            <GridItem key={`category-${category.id}`}>
              <Category
                className="relative"
                data-hidden={isHidden}
                to={`/categories/${category.id}`}
              >
                <CategoryInner
                  style={
                    isHidden
                      ? { opacity: '0.35', filter: 'grayscale(100%)' }
                      : {}
                  }
                >
                  <StyledImageWrapper>
                    {renderImage(category.media, category.name)}
                  </StyledImageWrapper>
                  <CategoryTextBox>
                    <CategoryTitle>{category.name}</CategoryTitle>
                    {hasQuestions && (
                      <div className="mt-1">
                        <div className="text-gray-400 text-sm mb-1">
                          <span>Lernfortschritt</span>
                        </div>
                        <Progress
                          className="rounded"
                          value={questionProgress}
                          height="2"
                          colorScheme={allQuestionsRight ? 'green' : 'blue'}
                        />
                      </div>
                    )}
                  </CategoryTextBox>
                </CategoryInner>
                {isHidden && (
                  <div className="flex items-center absolute top-4 right-4">
                    <span className="mr-2">Ausgeblendete Kategorie</span>
                    <Icon
                      className="w-6 h-6 text-gray-500 flex-shrink-0"
                      name="alert-circle"
                    />
                  </div>
                )}
              </Category>
            </GridItem>
          )
        })}
    </FlexGrid>
  )
}

const renderImage = (media, alt, classNames = '') => {
  if (!media || !media.image_formats)
    return (
      <ImagePlaceholder
        styledHeight="calc(8rem + 12vw)"
        styledMaxHeight="18rem"
      />
    )

  // Find tiny format and save as object in tinyImage
  let tinyImage = media.image_formats.find(format => format.type === 'tiny')
  // Fallback for tinyImage
  tinyImage = tinyImage ? tinyImage : media

  // Find large format and save as object in largeImage
  let largeImage = media.image_formats.find(format => format.type === 'large')
  // Fallback for largeImage
  largeImage = largeImage ? largeImage : media

  return (
    <ProgressiveImage
      src={`${API_URL}${largeImage.image_url}`}
      placeholder={`${API_URL}${tinyImage.image_url}`}
    >
      {(src, loading) => (
        <img
          className={`w-full transition-all duration-200 ${classNames}`}
          style={{
            filter: loading ? 'blur(10px)' : 'blur(0)',
            opacity: loading ? 0.5 : 1,
          }}
          src={src}
          alt={alt}
        />
      )}
    </ProgressiveImage>
  )
}

export default CategoryGrid
