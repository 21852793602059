import React, { useEffect, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import InfoSection from 'components/InfoSection'
import LoadingLayer from 'components/LoadingLayer'
import OffCanvasNav from 'components/OffCanvasNav'
import ScrollWrapper from 'components/ScrollWrapper'
import ShowProcessStep from 'scenes/Categories/Process/ShowProcessStep'
import ShowProcessGraphic from './ShowProcessGraphic'

import { useBackButton } from 'context/back-button'
import { useProcess } from 'data/processes'
import { useCategory } from 'data/categories'

const StyledShowProcessWrapper = styled.div`
  ${tw`flex flex-col bg-white relative`}
`

const StyledScrollWrapper = styled(ScrollWrapper)`
  ${tw`relative w-full bg-white overflow-x-auto`}

  transition: margin-left 0.5s;
`

const ShowProcess = () => {
  const { cid, pid } = useParams()
  const { path } = useRouteMatch()
  const { setBackTo } = useBackButton()
  const [showNavigation, setShowNavigation] = useState(() => {
    if (parseInt(localStorage.getItem('guido-nav-open')) === 1) {
      return true
    } else if (parseInt(localStorage.getItem('guido-nav-open')) === null) {
      return true
    } else if (parseInt(localStorage.getItem('guido-nav-open')) === 0) {
      return false
    } else {
      return true
    }
  })

  const [currentStep, setCurrentStep] = useState(null)
  const naviWidth = '22rem'

  const openNavigation = () => {
    localStorage.setItem('guido-nav-open', 1)
    setShowNavigation(true)
  }
  const closeNavigation = () => {
    localStorage.setItem('guido-nav-open', 0)
    setShowNavigation(false)
  }

  useEffect(() => {
    if (!currentStep) {
      setBackTo(`/categories/${cid}`)
    }

    if (currentStep) {
      if (!currentStep.parent_id) {
        setBackTo(`/categories/${cid}/processes/${pid}`)

        return
      }

      if (currentStep.parent_id) {
        setBackTo(
          `/categories/${cid}/processes/${pid}/${currentStep.parent_id}`
        )

        return
      }
    }
  }, [currentStep, cid, pid, setBackTo])

  const {
    isLoading: isCategoryLoading,
    isError: isCategoryError,
    isForbidden: isCategoryForbidden,
  } = useCategory(cid)

  const {
    process,
    isLoading: isProcessLoading,
    isError: isProcessError,
  } = useProcess(pid)

  if (
    isProcessLoading ||
    isProcessError ||
    isCategoryLoading ||
    isCategoryError ||
    isCategoryForbidden
  ) {
    return (
      <LoadingLayer
        isError={isProcessError}
        isLoading={isProcessLoading}
        isForbidden={isCategoryForbidden}
      />
    )
  }

  return (
    <StyledShowProcessWrapper>
      <InfoSection bookmark process={process} currentStep={currentStep} />
      <div className="flex">
        <OffCanvasNav
          process={process}
          currentStep={currentStep}
          onOpen={openNavigation}
          onClose={closeNavigation}
          isOpen={showNavigation}
          naviWidth={naviWidth}
          className="top-14"
        />
        <StyledScrollWrapper
          className={`${
            showNavigation ? 'ml-72' : ''
          } mt-14 min-h-show-process-container`}
        >
          <Switch>
            <Route exact path={`${path}`}>
              <ShowProcessGraphic
                process={process}
                setStepInBreadcrumb={setCurrentStep}
              />
            </Route>
            <Route
              path={`${path}/:sid`}
              component={() => (
                <ShowProcessStep setStepInBreadcrumb={setCurrentStep} />
              )}
            />
          </Switch>
        </StyledScrollWrapper>
      </div>
    </StyledShowProcessWrapper>
  )
}

export default ShowProcess
