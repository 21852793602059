import React from 'react'

import Tippy from '@tippyjs/react'
import Icon from 'components/IconLoader'

const UnpublishedIcon = ({ className = '', entity, size = 5 }) => {
  const isPublished = Boolean(entity.published_at)
  const isDeactivated = Boolean(entity.deactivated_at)

  const contentTextAffix = isDeactivated
    ? 'ist deaktiviert'
    : 'nicht veröffentlicht'

  const content =
    entity.__type === 'Category'
      ? `Kategorie ${contentTextAffix}`
      : entity.__type === 'Process'
      ? `Prozess ${contentTextAffix}`
      : entity.__type === 'Step'
      ? `Schritt ${contentTextAffix}`
      : contentTextAffix.charAt(0).toUpperCase() + contentTextAffix.slice(1)

  return (
    (!isPublished || isDeactivated) && (
      <Tippy content={content}>
        {/* div because tippy refs towards it and icon cant handle that */}
        <div>
          <Icon
            className={`${className} w-${size} h-${size} text-gray-500 flex-shrink-0 mr-2`}
            name={isDeactivated ? 'cloud-off' : 'eye-off'}
          />
        </div>
      </Tippy>
    )
  )
}

export default UnpublishedIcon
